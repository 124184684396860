.startup-projects-main {
  display: flex;
}

.startup-projects-main > * {
  flex: 1;
  margin-bottom: 30px;
}

.startup-project-image > img {
  max-width: 100%;
  height: auto;
}

.grid{
  display: grid;
  flex-wrap: wrap;
  /* Supports Grid */
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: minmax(200px, auto);
  grid-gap: 1em;
}

.container {
  position: relative;
  width: 100%;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container:hover .image {
  opacity: 0.3;
}

.container:hover .middle {
  opacity: 1;
}

.text {
  background-color: #999796;
  color: white;
  font-size: 14px;
  padding: 16px 32px;
}

.pointer {
  cursor: pointer;
}

.card-text{
  font-size: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .startup-project-image {
    display: none;
  }
  .project-subtitle{
    font-size: 16px;
    text-align: center;
  }
  .card-text{
    font-size: 16px;
    text-align: center;
  }
}
