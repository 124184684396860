.page_stl {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 3rem;
}

.responsive {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  height: auto;
}

.page-text {
  font-size: 70px;
  line-height: 1.1;
}

.page-text-p {
  font-size: 30px;
  line-height:30px;
}

/* Media Query */
@media (max-width: 1380px) {
  .page-text-p {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-text-p {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
  .greeting-main {
    display: block;
  }
}
